import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import { WmkLink } from "wmk-lib";
import styled from "styled-components";
import Title from "../../../common/Section/SectionTitle";
import get from "lodash/get";

const Wrap = styled.div`
  margin: 4rem 0;
`;

const WrapCont = styled(Container)`
  .row {
    a {
      &:hover {
        text-decoration: none;
      }
      p {
        text-align: center;
        margin-top: 1rem;
        font-size: 1.3rem;
      }
    }
  }
`;

const LearnAboutOtherMaterials = ({ current }) => {
  // console.log("current: ", current)
  const materials = useStaticQuery(graphql`
    query {
      materials: allContentfulMaterials(sort: { fields: order, order: ASC }) {
        edges {
          node {
            name
            slug
            image {
              gatsbyImageData
              title
            }
          }
        }
      }
    }
  `).materials.edges.map((p) => p.node);
  // console.log("materials: ", materials)
  const otherMaterials = [];
  materials.map((material) => {
    return material.name !== current ? otherMaterials.push(material) : null;
  });
  // console.log("otherMaterials: ", otherMaterials)
  return (
    <Wrap>
      <WrapCont>
        <Title title="LEARN ABOUT OTHER MATERIALS" />
        <p>
          Looking for something else? Take a look at the other types of siding
          material we offer!
        </p>
        &nbsp;
        <Row>
          {otherMaterials.map((material, i) => {
            const fluid = get(material, `image.gatsbyImageData`);
            const alt =
              material.image && material.image.title
                ? material.image.title
                : null;
            return (
              <Col xs={6} lg={3} key={material.name + i}>
                <WmkLink to={`/material/` + material.slug}>
                  {fluid ? <GatsbyImage image={fluid} alt={alt} /> : null}
                  <p>{material.name}</p>
                </WmkLink>
              </Col>
            );
          })}
        </Row>
      </WrapCont>
    </Wrap>
  );
};

export default LearnAboutOtherMaterials;
