import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/MainLayout";
import MaterialPage from "../components/routes/IdentifyingNeedsPage/MaterialPage/MaterialPage";
// import { SEO } from "wmk-lib";
// import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
//import get from "lodash/get";

const MaterialNode = ({ data }) => {
  return (
    <Layout layoutProps={{ logoColor: "black", showCta: true }}>
      {/* <SEO
        title={get(data, `material.name`)}
        path={`/material/`}
        slug={get(data, `material.slug`)}
        // description={desc}
        ogImage={null}
        twitterImage={null}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      /> */}
      <MaterialPage data={data} />
    </Layout>
  );
};

export const query = graphql`
  query materialQuery($slug: String!) {
    material: contentfulMaterials(slug: { eq: $slug }) {
      ...NodeMaterialsFields
    }
    download: contentfulGlobal(title: { eq: "Download Our Brochure" }) {
      ...NodeGlobalFields
    }
    downloadPreview: contentfulAsset(title: { eq: "PDF Preview" }) {
      title
      gatsbyImageData
    }
    downloadBG: contentfulAsset(title: { eq: "Download BG" }) {
      title
      gatsbyImageData
    }
  }
`;

export default MaterialNode;
