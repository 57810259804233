import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { WmkLink } from "wmk-lib";
import get from "lodash/get";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../../../common/Section/SectionTitle";
import Theme from "../../../../vars/ThemeOptions";
import { RichTextReact } from "../../../RichTextReact/RichTextReact";
import { RichText } from "../../../../classes/RichText";

const Wrap = styled.div`
  padding-bottom: 4rem;
  position: relative;
  width: 100vw;
  height: 100%;
  .bg-wrap {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const WrapCont = styled(Container)`
  position: relative;
  z-index: 10;
  padding-top: 1rem;
  color: white;
  p {
    color: #d0d0d0 !important;
  }
  .subtitle-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .subtitle-wrap.check {
    margin-left: 6px;
    .gatsby-image-wrapper {
      width: 2.5rem;
      height: 2.6em;
      position: relative;
      top: -10px;
    }
  }
  .subtitle-wrap.x {
    .gatsby-image-wrapper {
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
      top: -5px;
      margin-right: 5px;
    }
  }
  .pros-cons-wrap {
    width: 75%;
    margin-left: 5%;
  }
  .brands-wrap {
    h3 {
      margin: 2rem 0;
    }
    .logos-wrap {
      position: relative;
      left: 2.5rem;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .gatsby-image-wrapper {
          width: 100%;
        }
      }
    }
  }
  .cta-col {
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.5rem;
      text-shadow: 2px 2px 2px black;
    }
    a {
      background: ${Theme.hex("primary")};
      color: white;
      padding: 5px 15px;
      text-transform: uppercase;
      font-size: 1.5rem;
      box-shadow: 1px 1px 6px black;
    }
  }
  @media only screen and (max-width: 990px) {
    .intro-wrap {
      width: 90%;
    }
    .subtitle-wrap {
      margin: 2rem 0 1rem 0;
    }
    .pros-cons-wrap {
      width: 70%;
      margin-left: 0;
    }
    .brands-wrap {
      .logos-wrap {
        left: 0;
      }
    }
  }
`;

const ComponentName = ({ data }) => {
  // console.log("data in material hero: ", data)
  const material = data.material && data.material;
  const images = useStaticQuery(graphql`
    query {
      bg: contentfulAsset(title: { eq: "Material BG" }) {
        title
        gatsbyImageData
      }
      check: contentfulAsset(title: { eq: "Check" }) {
        title
        gatsbyImageData
      }
      x: contentfulAsset(title: { eq: "x" }) {
        title
        gatsbyImageData
      }
    }
  `);

  const bgFluid = get(images, `bg.gatsbyImageData`);
  const bgAlt = get(images, `bg.title`);

  const checkFluid = get(images, `check.gatsbyImageData`);
  const checkAlt = get(images, `check.title`);

  const xFluid = get(images, `x.gatsbyImageData`);
  const xAlt = get(images, `x.title`);

  const brands = data.material.brands;
  const rt1 = new RichText(get(material, `introduction`));
  const rtPros = new RichText(get(material, `pros`));
  const rtCons = new RichText(get(material, `cons`));
  return (
    <Wrap>
      <div className="bg-wrap">
        {bgFluid ? <GatsbyImage image={bgFluid} alt={bgAlt} /> : null}
      </div>
      <WrapCont>
        <Row>
          <Col md={12} lg={8}>
            <Title title={material.name} />
            <div className="intro-wrap">
              <RichTextReact content={rt1} />
            </div>
            <div className="subtitle-wrap check">
              <div>
                {checkFluid ? (
                  <GatsbyImage image={checkFluid} alt={checkAlt} />
                ) : null}
              </div>
              <div>
                <h3>PROS</h3>
              </div>
            </div>
            <div className="pros-cons-wrap">
              <RichTextReact content={rtPros} />
            </div>
            <div className="subtitle-wrap x">
              {xFluid ? <GatsbyImage image={xFluid} alt={xAlt} /> : null}
              <h3>CONS</h3>
            </div>
            <div className="pros-cons-wrap">
              <RichTextReact content={rtCons} />
            </div>
            {brands && (
              <div className="brands-wrap">
                <h3>BRANDS</h3>
                <div className="logos-wrap">
                  <Container>
                    <Row>
                      {brands.map((brand, i) => {
                        const fluid = get(brand, `logo.gatsbyImageData`);
                        const alt =
                          brand.logo && brand.logo.title
                            ? brand.logo.title
                            : null;
                        return (
                          <>
                            <Col xs={3} key={brand.title + i}>
                              <WmkLink to={brand.link} target="_blank">
                                {fluid ? (
                                  <GatsbyImage image={fluid} alt={alt} />
                                ) : null}
                              </WmkLink>
                            </Col>
                            <Col xs={1} />
                          </>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
              </div>
            )}
          </Col>
          <Col md={12} lg={4} className="cta-col">
            <p>Want to get started?</p>
            <a
              href={
                "https://www.coloradosidingrepair.com/schedule-appointment/"
              }
              target="_blank"
              rel="noreferrer noopener">
              Get a Quote
            </a>
          </Col>
        </Row>
      </WrapCont>
    </Wrap>
  );
};

export default ComponentName;
