import * as React from "react";
import styled from "styled-components";
import Hero from "./Hero";
import LearnAbout from "./LearnAboutOtherMaterials";
import Download from "../../IdentifyingNeedsPage/Download";
import Theme from "../../../../vars/ThemeOptions";
import { get } from "lodash";

const Wrap = styled.div`
  overflow: hidden;
  p {
    color: ${Theme.hex("grayText")};
  }
`;

const MaterialPage = ({ data }) => {
  return (
    <Wrap>
      <Hero data={data} />
      <LearnAbout current={data.material.name} />
      <br />
      <Download
        gtm={{
          event: "brochureDownload",
          campaign: "sidingNeeds",
          material: get(data, `material.slug`),
        }}
        data={data.download}
        preview={data.downloadPreview}
        bg={data.downloadBG}
      />
    </Wrap>
  );
};

export default MaterialPage;
